<template>
  <div class="main-content user-profile">
    <h1>Your User Profile</h1>
    <div class="filtered-list-item-two-columns full-page-item" style="grid-template-columns: 1fr 33%; padding: 0 1em 0 0;">
        <ContactForm :contact="detail" :required="['first_name', 'last_name', 'email_address']" @update="updateItemFromChild" />
        <div>
            <div class="section-heading">User Details</div>
            <label>Username</label>
            <input type="text" v-model="detail.user_name"/>
            <label>New Password</label>
            <PasswordInput v-model="detail.new_password" />
            <label>Retype Password</label>
            <PasswordInput v-model="detail.retype_password" />
            <label>Last Login</label>
            <input type="text" v-model="detail.last_login" disabled>
            <label>Last Password Change</label>
            <input type="text" v-model="detail.last_password_change" disabled>
        	<div class="control-buttons">
        		<div class="button" @click="saveItem()"><LabelWithLoadingIcon :loading="saving" label="Save Profile" /></div>
                <Message :message="message" :error="errorStatus" delay="3" :key="message" />
        	</div>
        </div>
    </div>
  </div>
</template>

<script>
import ContactForm from '@/components/ContactForm'
import LabelRequired from '@/components/LabelRequired.vue'
import LabelWithLoadingIcon from '@/components/LabelWithLoadingIcon.vue'
import PasswordInput from '@/components/PasswordInput.vue'
import Message from '@/components/Message.vue'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
export default {
	name: 'user-profile',
    components: {
        ContactForm,
        LabelRequired,
        LabelWithLoadingIcon,
        PasswordInput,
        Message
    },
	data() {
		return {
			loading: false,
			detail: {},
            message: '',
            errorStatus: false,
            saving: false,
            saved: false,
        }
    },
    computed: {
        // mix the getters into computed with object spread operator
        ...mapGetters([
            'getLoggedIn',
            'getUser'
        ])
    },
    created() {
		this.getItem()
    },
	methods: {
        ...mapActions([
            'apiCall', // map `this.apiCall()` to `this.$store.dispatch('apiCall')`
        ]),
        getItem() {
            let userId = this.getUser.user_id
            if (!userId) { return }
            if (this.loading) { return }
		    this.message = ''
            this.errorStatus = false
			this.loading = true
    		this.apiCall({ destination: 'get_item_user', data: { user_id: userId, is_profile: true } }).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
					this.detail = obj.detail
    			} else {
					this.message = obj.message
                    this.errorStatus = true
				}
    		})
        },
        updateItemFromChild(contactCopy) {
            // ContactForm emits an update event when keyup is fired
			for (const [key, value] of Object.entries(contactCopy)) {
                this.detail[key] = value
			}
        },
        saveItem() {
            if (this.saving) { return }
		    this.message = ''
            this.errorStatus = false
			if (!this.detail.first_name || !this.detail.last_name) {
				this.message = 'First and Last Name are required'
                this.errorStatus = true
				return
			}
			if (!this.detail.email_address || !this.isValidEmailAddress(this.detail.email_address)) {
				this.message = 'Complete Email Address required'
                this.errorStatus = true
				return
			}
            if (!this.detail.user_name) {
                this.message = 'Username is required'
                this.errorStatus = true
                return
            }
            if (this.detail.user_id == 'new' && !this.detail.new_password && !this.detail.retype_password) {
                this.message = 'New Password and Retype Password are required'
                this.errorStatus = true
                return
            }
            if (this.detail.new_password && this.detail.retype_password != this.detail.new_password) {
                this.message = 'New Password and Retype Password must match'
                this.errorStatus = true
                return
            }
            if (this.detail.retype_password && this.detail.retype_password != this.detail.new_password) {
                this.message = 'New Password and Retype Password must match'
                this.errorStatus = true
                return
            }
            if (!this.detail.user_type_id) {
                this.message = 'User Type is required'
                this.errorStatus = true
                return
            }
            // first save contact info
            if (this.saving) { return }
			this.saving = true
    		this.apiCall({ destination: 'validate_new_user_name', data: { user_id: this.detail.user_id, user_name: this.detail.user_name, password: this.detail.new_password } }).then(obj => {
    			if (obj.status == 'success') {
            		this.apiCall({ destination: 'save_item_contact', data: this.detail }).then(obj => {
            			if (obj.status == 'success') {
                            this.detail.contact_id = obj.contact_id
                            let userData = {
                                user_id: this.detail.user_id,
                                contact_id: this.detail.contact_id,
                                user_name: this.detail.user_name,
                                new_password: this.detail.new_password,
                                user_type_id: this.detail.user_type_id,
                                force_password_change: this.detail.force_password_change,
                                locked: this.detail.locked,
                                inactive: this.detail.inactive
                            }
                    		this.apiCall({ destination: 'save_item_user', data: userData }).then(obj => {
                				this.saving = false
                    			if (obj.status == 'success') {
                                    this.detail.user_id = obj.user_id
                                    this.message = 'User profile saved!'
                                    this.saved = true
                                } else {
                                    this.message = obj.message
                                    this.errorStatus = true
                                }
                            })
            			} else {
        				    this.saving = false
        					this.message = obj.message
                            this.errorStatus = true
        				}
            		})
                } else {
    				this.saving = false
                    this.message = obj.message
                    this.errorStatus = true
                }
            })
        }
    }
}
</script>
